import App from './App.vue'
import router from '@/router'
import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'animate.css'
import './index.css'

const shopifyGuy = createApp(App)
shopifyGuy.use(router)
shopifyGuy.use(createHead())
shopifyGuy.use(VueSweetalert2)

// This code below is for globally registered componets prefixed with App
const requireComponent = require.context('./components', true, /App[A-Z]\w+\.(vue|js)$/)
requireComponent.keys().forEach(function (fileName) {
  let baseComponentConfig = requireComponent(fileName)
  baseComponentConfig = baseComponentConfig.default || baseComponentConfig
  const baseComponentName = baseComponentConfig.name || fileName.replace(/^.+\//, '').replace(/\.\w+$/, '')
  shopifyGuy.component(baseComponentName, baseComponentConfig)
})

shopifyGuy.config.globalProperties.$filters = {
  truncate(value, length) {
    if (value.length > length) {
      return value.substring(0, length) + '...'
    } else {
      return value
    }
  }
}

shopifyGuy.mount('#app')
