import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import(/* webpackChunkName: "HomePage" */ '@/pages/HomePage')
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: () => import(/* webpackChunkName: "AboutPage" */ '@/pages/AboutPage')
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: () => import(/* webpackChunkName: "ContactPage" */ '@/pages/ContactPage')
  },
  {
    path: '/blog',
    name: 'BlogPage',
    component: () => import(/* webpackChunkName: "BlogPage" */ '@/pages/BlogPage')
  },
  {
    path: '/blog/:slug',
    name: 'BlogPost',
    component: () => import(/* webpackChunkName: "BlogPost" */ '@/pages/BlogPost')
  },
  {
    path: '/privacy',
    name: 'PrivacyPage',
    component: () => import(/* webpackChunkName: "PrivacyPage" */ '@/pages/PrivacyPage')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    const scroll = {}
    if (to.meta.toTop) scroll.top = 0
    if (to.meta.smoothScroll) scroll.behavior = 'smooth'
    return scroll
  }
})

export default router
