<template>
  <header
    class="
      fixed
      flex
      justify-between
      items-center
      w-full
      px-4
      h-28
      pb-4
      lg:px-12
      bg-white
      z-10
    "
  >
    <router-link :to="{ name: 'HomePage' }" class="logo">
      <img
        src="https://res.cloudinary.com/dch4i7gjv/image/upload/v1651181579/Your_Shopify_300-no-bg_feq1cp.png"
        alt="your shopify guy"
        class="mb-3 h-24 mt-2 hover:scale-125"
      />
    </router-link>
    <nav>
      <button class="md:hidden" @click="toggleNavBarOpen">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8 text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
      <ul
        class="
          fixed
          left-0
          right-0
          min-h-screen
          p-4
          mt-8
          bg-white
          space-y-5
          transform
          translate-x-full
          transition
          duration-200
          md:relative
          md:flex
          md:min-h-0
          md:space-y-0
          md:space-x-6
          md:p-0
          md:translate-x-0
          md:items-center
        "
        :class="{
          'translate-x-full': !navBarOpen,
          'translate-x-0': navBarOpen,
        }"
      >
        <li class="text-primary md:hover:text-blue-900 md:hover:scale-125">
          <router-link :to="{ name: 'AboutPage' }">About</router-link>
        </li>
        <li class="text-primary md:hover:text-blue-900 md:hover:scale-125">
          <router-link :to="{ name: 'BlogPage' }">Blog</router-link>
        </li>
        <li class="text-primary md:hover:text-blue-900 md:hover:scale-125">
          <router-link :to="{ name: 'ContactPage' }">Contact </router-link>
        </li>
        <li>
          <button
            class="
              bg-primary
              hover:bg-blue-900
              text-white
              font-bold
              py-2
              px-4
              rounded
              hover:scale-125
            "
          >
            Get Started
          </button>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      navBarOpen: false,
    };
  },
  methods: {
    toggleNavBarOpen() {
      this.navBarOpen = !this.navBarOpen;
    },
  },
  created() {
    // eslint-disable-next-line no-unused-vars
    this.$router.beforeEach((to, from) => {
      this.navBarOpen = false;
    });
  },
};
</script>

<style>
</style>