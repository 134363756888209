<template>
  <AppHead>
    <title>Your Shopify Guy</title>
    <meta
      name="description"
      content="Connect with your ideal customer and help them get to where they want to be"
    />

    <!-- Social -->
    <meta property="og:title" content="Your Shopify Guy" />
    <meta
      property="og:description"
      content="Connect with your ideal customer and help them get to where they want to be"
    />
    <meta
      property="og:image"
      content="https://res.cloudinary.com/dch4i7gjv/image/upload/h_512,w_1024/v1650215927/business-people_prnwyt.jpg"
    />

    <!-- Twitter -->
    <meta name="twitter:title" content="Your Shopify Guy" />
    <meta
      name="twitter:description"
      content="Connect with your ideal customer and help them get to where they want to be"
    />
    <meta
      name="twitter:image"
      content="https://res.cloudinary.com/dch4i7gjv/image/upload/v1650215927/business-people_prnwyt.jpg"
    />
    <meta name="twitter:card" content="summary_large_image" />
  </AppHead>
  <TheNavBar />
  <router-view v-slot="{ Component }">
    <transition name="route" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
  <TheFooter />
</template>

<script>
import TheNavBar from "@/components/TheNavBar";
import TheFooter from "@/components/TheFooter";

export default {
  name: "App",
  components: { TheNavBar, TheFooter },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&family=Playfair+Display:ital,wght@0,500;0,600;0,700;1,400;1,500&display=swap");
/* route transitions */
.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-active {
  transition: all 0.3s ease-in;
}
</style>
